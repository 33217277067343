@media screen and (max-width: 768px) {
  .only-mobile {
    display: block;
  }

  .vetchat-brand img {
    margin-left: 50px;
  }

  .nav.navbar-nav li {
    text-align: right;
  }
  .nav.navbar-nav li.chat-now a.btn-vetchat {
    float: right;
  }

  form.login div.extra-actions label {
    float: none;
    font-weight: 500;
    width: 100%;
    text-align: center;
  }

  form.login div.extra-actions a.forgot-link {
    float: none;
    width: 100%;
  }

  .chat-action-btns {
    text-align: center;
  }
  .chat-action-btns button {
    margin-bottom: 20px;
  }

  .account-action-btns div.content-link {
    text-align: center !important;
  }

  .row.consultation-history .individual-consult {
    margin-bottom: 40px;
  }

  .row.individual-consult .footer a.consult-link,
  .row.consultation-history .individual-consult:hover .footer div.info {
    display: block;
  }

  .vetchat-purchase.container-fluid .container > .row > .col-sm-6 {
    border-radius: 0 !important;
    padding-left: 15px;
    padding-right: 15px;
  }

  .vetchat-purchase.container-fluid .container > .row > .col-sm-6 > div {
    width: 80%;
    margin: 0 auto;
  }

  .row.display-flex {
    display: block;
    flex-wrap: none;
  }
  .row.display-flex > [class*='col-'] {
    display: block;
    flex-wrap: none;
  }

  .how-vetchat-works .how-steps {
    display: block;
    width: 100%;
  }

  /* Mobile menu */
  .navbar-collapse.collapse.in {
    max-height: auto;
  }

  .navbar-collapse.collapseing ul.nav.navbar-nav {
    width: 100%;
    position: relative;
    /* top: 50px; */
    right: auto;
  }
  .navbar-collapse.collapse.in .nav.navbar-nav {
    position: relative;
    top: 110px;
    right: auto;
  }

  /* Video Chat Picture in Picture fro mobile devices */

  #video-chat > div.col-sm-12.col-md-6:first-of-type {
    position: relative;
  }
  #video-chat .mobileVideo {
    position: absolute;
    width: 150px;
    right: 40px;
    bottom: 40px;
    border: 2px solid #fff;
  }

  /* remove fixed menu on chat pages */
  body.chat-page .navbar-default.navbar-fixed-top {
    position: relative;
  }

  body.chat-page div.submit-message textarea {
    min-height: 150px !important;
  }

  div.purchase-container.vetchat-purchase form,
  div.offer.form-view form {
    display: block;
  }
}

@media screen and (min-width: 768px) {
  .vet-tab-header {
    padding-right: 0px;
  }
  .vet-tab-header li {
    border-right: none !important;
  }

  .vets-tabs-content {
    padding-left: 0px;
  }

  /* .how-vetchat-works .how-steps {
        display: flex;
        display: -webkit-flex;
        flex-wrap: wrap;
    } */

  .message-claire .claire-msg-image,
  .message-claire .message-quick-chat {
    padding-left: 0px;
    padding-right: 0px;
  }

  .message-claire .message-quick-chat > div {
    margin-top: 10%;
  }
}

@media screen and (max-width: 510px) {
  .chat-action-btns button + button {
    margin-left: 0 !important;
  }
}

@media screen and (max-width: 332px) {
  .btn + .btn {
    margin-left: 5px !important;
  }
}

@media screen and (max-width: 430px) {
  .edit-user-form .action-buttons {
    text-align: center;
  }

  .edit-user-form .action-buttons .btn + .btn {
    margin-left: 0 !important;
  }
}
