/*** Color Variables ***/
$themeColor: #002855;
$themeColorHover: #d25111;
$themeColor2: #003058;
$themeColor2Hover: #424242;
$themeColor3: #00aeef;

$primaryColor: #ccc;
$secondaryColor: #aaa;

/** Font faces */
@font-face {
  font-family: 'DINCondensedWebNormal';
  src: url('./fonts/DIN_Condensed_Normal/DIN_Condensed_Normal.eot');
  src: url('./fonts/DIN_Condensed_Normal/DIN_Condensed_Normal.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/DIN_Condensed_Normal/DIN_Condensed_Normal.woff2')
      format('woff2'),
    url('./fonts/DIN_Condensed_Normal/DIN_Condensed_Normal.woff') format('woff'),
    url('./fonts/DIN_Condensed_Normal/DIN_Condensed_Normal.ttf')
      format('truetype'),
    url('./fonts/DIN_Condensed_Normal/DIN_Condensed_Normal.svg#DINCondensedWeb-Regular')
      format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DINCondensedWebLight';
  src: url('./fonts/DIN_Condensed_Light/DIN_Condensed_Light.eot');
  src: url('./fonts/DIN_Condensed_Light/DIN_Condensed_Light.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/DIN_Condensed_Light/DIN_Condensed_Light.woff2') format('woff2'),
    url('./fonts/DIN_Condensed_Light/DIN_Condensed_Light.woff') format('woff'),
    url('./fonts/DIN_Condensed_Light/DIN_Condensed_Light.ttf')
      format('truetype'),
    url('./fonts/DIN_Condensed_Light/DIN_Condensed_Light.svg#DINCondensedWeb-Light')
      format('svg');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'DINCondensedWebLight', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'DINCondensedWebNormal', sans-serif;
  // text-transform: uppercase;
  line-height: 1.2;
  color: $themeColor;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Custom Css */
div#main-content {
  min-height: 45vh;
  margin-bottom: 80px;
}

.hidden {
  display: none;
}

.only-mobile {
  display: none;
}
.clear {
  clear: both;
}

.clearfix {
  overflow: auto;
}

.clearfix:after {
  clear: both;
  content: '';
  display: table;
}
.space-top {
  margin-top: 80px;
}
.space-bottom {
  margin-bottom: 80px;
}
.space-small-top {
  padding-top: 40px;
}

.space-small-bottom {
  padding-bottom: 40px;
}

.break {
  clear: both;
}

.bold {
  font-weight: 600;
}

a {
  color: #37c0cd;
}

/* a:hover, a:focus {
    color: #00CCD8;
 } */

span.required-red {
  color: #f00;
  font-size: 20px;
}

input.form-control,
select.form-control {
  min-height: 47px !important;
  width: 100%;
  padding: 10px 12px !important;
  border-radius: 0px !important;
}

textarea.form-control {
  width: 100%;
  min-height: 200px !important;
  border-radius: 0px !important;
}
.content-link {
  color: #37c0cd;
  cursor: pointer;
}

.content-link:hover {
  /* color: #37c0cd; */
  text-decoration: underline;
}
.content-link.link-chevron:after,
a.link-chevron:after {
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  content: '\e258';
  font-size: 12px;
  padding-left: 10px;
}

.btn-vetchat + .btn-vetchat,
.btn + .btn {
  margin-left: 40px;
}

.btn-transparent {
  background: transparent;
  border: 2px solid #37c0cd !important;
}

.btn-transparent:hover,
.btn-transparent:focus {
  background-color: #37c0cd;
  border-color: #37c0cd !important;
  outline: 0 !important;
  color: #fff;
}
/* Header */
.navbar-vetchat {
  margin-bottom: 0px;
  background-color: #fff;
}

.navbar-vetchat .container {
  position: relative;
}

.navbar-vetchat .container ul.navbar-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
}

ul.navbar-nav li.chat-now a.btn {
  padding-top: 6px;
  /* padding-bottom: 10px; */
  text-transform: uppercase;
  max-height: 36px;
}

header {
  padding-bottom: 70px;
}
.navbar-vetchat .btn.btn-vetchat {
  margin-top: 6px;
  margin-bottom: 0px;
  border-radius: 18px;
}

.navbar-vetchat .vetchat-brand img {
  padding: 5px 0;
}
/* Footer */
footer {
  background-color: #333;
  color: #fff;

  /* .row {
    margin-top: 60px;
  } */

  #mc_embed_signup input,
  #mc_embed_signup select {
    color: #000;
    max-height: 36px;
    width: 80%;
  }

  #mc_embed_signup select {
    margin: 0;
    margin-bottom: 20px;
    width: 80%;
    padding: 6px 14px 6px;
  }

  #mc_embed_signup input[type='submit'] {
    max-width: 200px;
    border-radius: 16px;
  }

  ul {
    list-style: none;
    padding-inline-start: 0px;
  }

  .footer-links-list a {
    color: #888;
  }

  .footer-links-list a:hover {
    color: #fff;
  }

  .footer-links-list.social li {
    display: inline-block;
  }

  .footer-links-list.social img {
    max-width: 40px;
    max-height: 40px;
  }

  .copyright {
    margin-bottom: 40px;
    font-weight: 500;
  }

  .copyright a {
    color: #37c0cd;
  }
}

.row .section-title {
  text-align: center;
  padding-top: 80px;
  padding-bottom: 40px;
}

/* Header banner */
.header-banner {
  min-height: 353px;
  display: block;
  background-size: cover !important;
  position: relative;
}

.header-banner .title {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  text-transform: capitalize;
  font-size: 58px;
}

/* Home Page */
.hero-container {
  background: url('/images/home_bg_1.jpg') no-repeat center;
  background-size: cover;
  min-height: 500px;
}

.home-hero .hero-content {
  width: 80%;
  margin: 70px auto 0;
  background-color: rgba(255, 255, 255, 0.6);
  padding: 30px 20px;
  border-radius: 10px;
}

.home-hero .hero-content h3 {
  margin-top: 30px;
}

.home-hero .hero-content .btn.btn-vetchat {
  height: 36px;
  border-radius: 18px;
  text-transform: uppercase;
  margin-top: 30px;
  margin-bottom: 20px;
}

.btn {
  min-height: 36px;
  border-radius: 4px !important;
  min-width: 104px;
  outline: none;
  padding-left: 60px !important;
  padding-right: 60px !important;
  // font-family: 'DIN Condensed';
  text-transform: capitalize;
}

.btn:focus {
  outline: none;
}

.btn.btn-vetchat,
.footer-hubspot .hs-submit .hs-button.primary.large.btn.btn-vetchat {
  background-color: $themeColor2 !important;
  color: #fff !important;
  background-image: none;
  border-color: $themeColor2;
}

.btn.btn-vetchat2 {
  background-color: $themeColor2 !important;
  color: #fff !important;
  background-image: none;
  border-color: $themeColor2;
  min-height: 36px;
  border-radius: 18px;
}

.footer-hubspot .hs-submit .hs-button.primary.large.btn.btn-vetchat {
  max-width: 120px;
}

.btn.btn-vetchat:hover {
  background-color: #707070 !important;
  border-color: #707070 !important;
}

.btn.btn-vetchat2:hover {
  background-color: $themeColor !important;
  border-color: $themeColor !important;
}

.steps-content .steps-image {
  max-width: 150px;
  margin: 0 auto;
}
.steps-content .steps-image img {
  width: 100%;
}

.steps-content h2,
.steps-content .steps-image {
  color: #fff;
  margin-bottom: 40px;
}

.steps-content h3 {
  margin-bottom: 40px;
}

.container-fluid.testimonials {
  background-color: #efefef;
  padding-bottom: 80px;
}

.testimonials .col-md-4 .single-testimonial {
  background-color: #fff;
  margin: 10px;
  padding: 20px 20px;
  border-radius: 1.2rem;
}

.single-testimonial .star-rating {
  color: #ffdb00;
}

.single-testimonial p.main-quote {
  color: $themeColor3;
}

.vetchat-videos {
  padding-bottom: 20px;
}

.vetchat-videos .video-content {
  position: relative;
}

.video-content img.video-thumbnail {
  width: 100%;
}

.vetchat-videos .video-content img.yt-play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
}

/* Home Page End*/

/* HOW IT WORKS PAGE */
.why-visit-vet-online {
  padding-bottom: 60px;
}
.why-content {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.why-content img {
  width: 150px;
  height: auto;
  margin-bottom: 20px;
}

.message-claire .claire-msg-image img {
  width: 100%;
}

.message-claire .row {
  background-color: #efefef;
}

.message-claire .message-quick-chat div {
  padding: 80px;
}

.why-visit-vet-online-last {
  margin-top: 80px;
  margin-bottom: 80px;
}

/* HOW IT WORKS PAGE END*/

/* General Page*/
.general-content {
  margin-top: 80px;
  margin-bottom: 80px;
}
/* General Page*/

/* Pricing Page */
.consult-type {
  border: 1px solid #ddd;
  margin-bottom: 40px;
}
.consult-type .consult-header h3 {
  padding-top: 12px;
  padding-bottom: 12px;
  color: #fff;
  margin: 0;
  text-transform: uppercase;
  font-size: 14px;
}

.consult-type .consult-price {
  /* opacity: 0.92; */
  color: #fff;
  position: relative;
}

/* .consult-type .consult-price:before {
    background: rgba(0,0,0,0.08);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
} */

.consult-type .consult-price span {
  opacity: 0.6;
  padding: 0 0 24px 0;
  margin: 0;
  display: block;
}
.consult-type .consult-price span.amount {
  font-size: 38px;
  font-weight: 500;
  line-height: 1;
  padding: 24px 0 5px 0;
  opacity: 1;
}

.consult-type .consult-purchase {
  display: block;
  margin: 20px 0 0px 0;
  padding: 20px 0 20px 0;
  text-align: center;
  background: #f9f9f9;
}

.consult-type .consult-purchase .btn {
  text-transform: uppercase;
}
.consult-type .consult-message p {
  margin: 0 20px;
  padding: 10px 0px;
}

.consult-type .consult-message {
  margin: 20px 0px;
}

.pricing-list > ul {
  padding-inline-start: 20px;
  padding-bottom: 40px;
}

.vetchat-service h4 {
  font-size: 24px;
}

.vetchat-service span.badge {
  font-size: 24px;
  line-height: 75px;
  width: 73px;
  height: 73px;
  border-radius: 50%;
  position: relative;
  background-color: #c0c0c0;
}

.vetchat-service span.badge i.fa {
  /* vertical-align: middle; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/* Pricing Page End*/

/* Contact Page */
.section-title.header-contact {
  text-align: left;
  padding-bottom: 0px;
  padding-top: 40px;
}

/* Contact Page End */

/* Login & Signup */
.container-fluid.container-login {
  background: #efefef url('/images/home_bg_1.jpg') no-repeat center center;
  background-size: cover;
}

.container-login .login-content,
.container-login .signup-content {
  background-color: rgba(255, 255, 255, 0.9);
  padding-top: 120px;
  padding-bottom: 120px;
  text-align: center;
}
form.login {
  font-weight: 500;
  font-size: 20px;
}

form.login div.image p {
  color: #00ccd8;
}
form.login div.form-group {
  text-align: center;
  position: relative;
}
form.login input.form-control {
  width: 70%;
  /* border-radius: 23.5px !important; */
  border: 2px solid #00ccd8;
  margin: 0 auto;
  padding-left: 40px !important;
}
form.login div.extra-actions,
form.form-signup div.extra-actions {
  width: 70%;
  margin: 0 auto;
}

form.login div.extra-actions label {
  float: left;
  font-weight: 500;
  width: 50%;
}
form.login div.extra-actions a.forgot-link {
  float: right;
  width: 50%;
  text-align: right;
}

form.login p.text-signup {
  color: #333;
}

form.login div.extra-actions a {
  color: #00ccd8;
}

form.login div.submit-login .btn.btn-full.btn-login {
  width: 100%;
  background-color: #00ccd8;
  color: #fff;
  height: 47px;
  border: 1px solid #00ccd8;
  font-size: 20px;
  border-radius: 23.5px;
  margin: 10px 0 20px;
}

div.form-group span.form-control-feedback,
div.signup-input span.form-control-feedback {
  position: absolute;
  top: 8px;
  left: 15.5%;
  display: inline-block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  pointer-events: none;
  color: #ccc;
}

div.signup-input {
  position: relative;
}

form.login label.checkbox-container input {
  display: inline-block;
  width: auto;
  margin-right: 10px;
}
/* Login & signup End */

/* Myaccount */
.btn.btn-consult {
  padding-left: 60px;
  padding-right: 60px;
  text-transform: uppercase;
  font-size: 18px;
  border-radius: 18px;
}

.btn.btn-consult:focus {
  outline: 0 !important;
}

.add-more-pets {
  padding-left: 0px;
  padding-right: 0px;
}

.pet-info span.pet-gender:before {
  font-family: 'Font Awesome 5 Free';
  content: '\f222';
  display: inline-block;
}

.pet-info span.pet-gender-1:before {
  content: '\f221';
}

.pet-info .pet-detail div.detail span {
  display: block;
  width: 100%;
  color: #ccc;
}

.pet-info h4.petName {
  text-transform: capitalize;
}

.pet-info h4.petName span.fa.fa-venus {
  transform: rotate(-45deg);
}

.row.consultation-history .individual-consult {
  border: 1px solid #f00;
  border-radius: 5px;
  margin: 0 5px 20px !important;
  width: 100%;
}

.row.consultation-history .individual-consult:hover {
  border: 1px solid #37c0cd;
}
.row.individual-consult div span {
  display: block;
  width: 100%;
  color: #ccc;
}
.row.individual-consult div.pet-name {
  text-align: left;
  padding-top: 16px;
  padding-bottom: 16px;
}

.row.individual-consult div.pet-query {
  text-align: right;
  padding-top: 16px;
  padding-bottom: 16px;
}

.row.individual-consult .footer {
  background-color: #ccc;
  color: #fff;
  margin-top: 18px;
}

.row.consultation-history .individual-consult:hover .footer {
  background-color: #37c0cd;
}

.row.individual-consult .footer div {
  padding: 10px 5px;
}

.row.individual-consult .footer a.consult-link {
  color: #fff;
  width: 100%;
  padding: 10px 5px;
  text-decoration: none;
}

.row.individual-consult .footer a.consult-link:hover {
  text-decoration: none;
}

.row.individual-consult .footer a.consult-link,
.row.consultation-history .individual-consult:hover .footer div.info {
  display: none;
}
.row.consultation-history .individual-consult:hover .footer a.consult-link {
  display: block;
}

/* Myaccount End */

/* AddEdit Pet */
.form-group .react-datepicker-wrapper,
.form-group .react-datepicker-wrapper .react-datepicker__input-container {
  display: block;
}
.add-edit-pet-form select {
  margin: 10px 0 20px;
}

/* Edit use form */
.row.edit-user-form {
  margin: -24px;
}

.row.edit-user-form form {
  padding: 16px 28px;
}

.stripe-element label {
  width: 100%;
}

input,
.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  width: 100%;
  padding: 10px 14px;
  font-size: 1em;
  border: 1px solid #ccc;
  outline: 0;
  border-radius: 0px;
  background: white;
}

input:disabled {
  background-color: #ccc;
}
div[role='dialog'] div[role='document'] > div:first-child {
  border-radius: 10px;
}

/* vetchat Purchase */

.vetchat-purchase.container-fluid,
.vetchat-subscribe.container-fluid {
  background-color: $themeColor2;
}

.vetchat-purchase.container-fluid .container,
.vetchat-subscribe.container-fluid .container {
  /* background: #fff; */
  margin-top: 80px;
  margin-bottom: 80px;
}

.vetchat-purchase.container-fluid .container > .row > .col-sm-6:first-child {
  border-radius: 20px 0 0 20px;
}

.vetchat-subscribe.container-fluid .container > .row > .col-sm-6:first-child {
  border-radius: 20px 0 0 20px;
  background-color: #f8f9fa;
}

.vetchat-purchase.container-fluid .container > .row > .col-sm-6:last-child,
.vetchat-subscribe.container-fluid .container > .row > .col-sm-6:last-child {
  border-radius: 0 20px 20px 0;
  background: #fff;
  padding-left: 40px;
  padding-right: 40px;
}

.vetchat-purchase.container-fluid .container .cart {
  background-color: #f8f9fa;
  padding-left: 40px;
  padding-right: 40px;
}
.vetchat-purchase .cart div.col-sm-6:nth-of-type(2n + 1) {
  text-align: left;
  padding-right: 0px;
}

.vetchat-purchase .cart div.col-sm-6:nth-of-type(2n) {
  text-align: right;
  padding-left: 0px;
}

.vetchat-purchase .consult p.title {
  color: #ccc;
}

.vetchat-purchase .coupon {
  min-height: 23px;
}

.vetchat-purchase .cart span.line {
  color: #ccc;
  display: block;
  width: 100%;
  min-height: 1px;
  border-bottom: 1px solid #ccc;
}

.vetchat-purchase .couponField {
  position: relative;
}

.vetchat-subscribe .subscribe-coupon {
  position: relative;
  width: 75%;
}

.vetchat-subscribe .subscribe-coupon input {
  margin-bottom: 5px;
}

.vetchat-subscribe .subscribe-coupon button,
.vetchat-purchase .couponField button {
  border-radius: 50%;
  background-color: #e6e6e6;
  border: 1px solid #e6e6e6;
  position: absolute;
  top: 10px;
  right: 0;
  width: 44px;
  height: 44px;
}

.vetchat-subscribe .subscribe-coupon button {
  top: 0;
  right: -20px;
}

.vetchat-subscribe .subscribe-coupon button:focus,
.vetchat-subscribe .subscribe-coupon button:hover,
.vetchat-purchase .couponField button:focus,
.vetchat-purchase .couponField button:hover {
  outline: none;
  background-color: $themeColor;
  border-color: $themeColor;
}

.vetchat-subscribe .discount-amount {
  padding-left: 10px;
  font-weight: 400;
}

.vetchat-subscribe .coupon-message {
  margin: 16px auto;
  display: block;
}

.btn.btn-pay {
  background-color: $themeColor2;
  color: #fff;
  border-color: $themeColor2;
  min-width: 150px;
}

.btn.btn-pay:hover,
.btn.btn-pay:focus {
  outline: none;
  background-color: #707070;
  border-color: #707070;
}

.row.display-flex {
  display: flex;
  flex-wrap: wrap;
}
.row.display-flex > [class*='col-'] {
  display: flex;
  flex-direction: column;
}

/* Custom Modal on textchat and video chat */
.custom-modal.not-visible {
  display: none;
}

.custom-modal {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.8);
  text-align: left;
}

.custom-modal-inner {
  width: 250px;
  margin: 0 auto;
  background-color: #fff;
}

.custom-modal-inner.large {
  width: 100%;
  max-width: 400px;
  padding: 10px 20px;
}

.custom-modal-content {
  width: 80%;
  margin: 20px auto;
}

.custom-modal-inner .modal-buttons {
  text-align: center;
  margin-bottom: 20px;
}

p.error-message {
  color: #f00;
  text-decoration: underline;
}

.custom-modal.endChat .custom-modal-inner {
  width: 400px;
  padding: 20px;
}

#video-chat video {
  width: 100%;
}

/* Text Chat box colors */
div#chatDiv div p {
  background-color: #fff !important;
  color: #424242;
}
div#chatDiv div.vet p {
  background-color: $themeColor2 !important;
  color: #fff;
}

div#chatDiv div.pet-owner p {
  color: #000 !important;
}
div.resource-single img {
  width: 100%;
}

/* text chat active user */
div.active-users span.typing-info {
  float: left;
  margin-left: 10px;
}

div.active-users span.active-user,
div.active-users span.not-online {
  float: right;
}

div.active-users span.active-user:after {
  content: ' ';
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 10px;
  border: 1px solid #5cb85c;
  border-radius: 50%;
  background-color: #5cb85c;
}

div.active-users span {
  line-height: 20px;
  padding: 4px 0px;
  font-weight: 600;
}

div.active-users {
  min-height: 28px;
  background-color: rgb(238, 238, 238);
  border: 1px solid rgb(84, 196, 198);
  border-top: none;
  border-bottom: none;
}

div.modal-consultation:after {
  content: '';
  display: block;
  max-height: 100%;
  min-height: 600px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
}

div.modal-consultation .modal-consultation-content {
  z-index: 1000;
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  min-width: 300px;
  max-width: 500px;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 20px;
}

div.modal-consultation .modal-consultation-content #chatDiv {
  height: 350px !important;
}

.fileNsubmit .btn-danger.btn-end {
  padding: 6px 12px;
}

.fileNsubmit .btn:last-of-type {
  background-color: $themeColor2;
}

.fileNsubmit button#upload-media {
  background: #fff;
  border-color: $themeColor2;
  color: #37c0cd;
  min-width: 54px;
  max-width: 54px;
  width: 54px;
}

.fileNsubmit button#upload-media:hover,
.fileNsubmit button#upload-media:focus {
  border-color: #ccc;
  color: #ccc;
}

.fileNsubmit .btn {
  margin-bottom: 8px;
}

select.form-control {
  margin: 10px 0 20px 0;
}

.adminMenu .nav.nav-pills li a {
  font-weight: 600;
  color: $themeColor2;
}

.adminMenu .nav.nav-pills li a:hover {
  background-color: $themeColor2Hover;
  color: #fff;
}

.adminMenu .nav.nav-pills li.active a {
  background-color: $themeColor2;
  color: #fff;
}

.row.page-about h2.section-title:not(first-child) {
  padding-top: 20px;
}

.select-search-box {
  width: 300px;
  position: relative;
  margin-bottom: 20px;
  background: #fff;
  border-radius: 4px;
}

.select-search-box--multiple {
  border-radius: 4px;
  box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.07);
  overflow: hidden;
}

.select-search-box::after {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  content: '\f078';
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  text-align: center;
  line-height: 50px;
  color: #222f3e;
  z-index: 1;
}

.select-search-box--multiple::after,
.select-search-box--focus::after {
  content: '\f077';
}

.select-search-box__icon--disabled::after {
  content: none !important;
}

.select-search-box--input::after {
  display: none !important;
}

.select-search-box__out {
  display: none;
}

.select-search-box__search {
  display: block;
  width: 100%;
  height: 50px;
  border: none;
  background: none;
  outline: none;
  font-size: 16px;
  padding: 0 20px;
  color: #222f3e;
  -webkit-appearance: none;
  box-sizing: border-box;
  position: relative;
  z-index: 2;
  cursor: pointer;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 50px;
  box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.07);
}

input.select-search-box__search {
  line-height: 1;
}

.select-search-box--multiple .select-search-box__search {
  box-shadow: none;
}

.select-search-box--input .select-search-box__search {
  cursor: text;
}

.select-search-box__search:focus {
  cursor: text;
}

.select-search-box__search--placeholder {
  font-style: italic;
  font-weight: normal;
}

.select-search-box input::-webkit-input-placeholder {
  color: #ccc;
  font-style: italic;
  font-weight: normal;
}

.select-search-box input::-moz-placeholder {
  color: #ccc;
  font-style: italic;
  font-weight: normal;
}

.select-search-box input:-moz-placeholder {
  color: #ccc;
  font-style: italic;
  font-weight: normal;
}

.select-search-box input:-ms-input-placeholder {
  color: #ccc;
  font-style: italic;
  font-weight: normal;
}

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

.select-search-box__select {
  display: none;
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  background: #fff;
  border-radius: 4px;
  overflow: auto;
  box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.07);
  z-index: 100;
  min-height: 49px;
}

.select-search-box--multiple .select-search-box__select {
  display: block;
  position: static;
  border-top: 1px solid #eee;
  border-radius: 0;
  box-shadow: none;
}

.select-search-box__select--display {
  display: block;
}

.select-search-box__option {
  font-size: 16px;
  font-weight: 400;
  color: #616b74;
  padding: 15px 20px;
  border-top: 1px solid #eee;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
}

.select-search-box__option:first-child {
  border-top: none;
}

.select-search-box__option--hover,
.select-search-box__option:hover {
  background: #f4f7fa;
}

.select-search-box__option--selected {
  background: #54a0ff;
  color: #fff;
  border-top-color: #2184ff;
}

.select-search-box__option--selected:hover,
.select-search-box__option--selected.select-search-box__option--hover {
  background: #2184ff;
  color: #fff;
  border-top-color: #2184ff;
}

.select-search-box__group {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;
  position: relative;
}

.select-search-box__group-header {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 0 10px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
}

.select-search-box {
  width: 100% !important;
}

.select-search-box__search {
  box-shadow: none !important;
  border: 1px solid #ccc !important;
}

.select-search-box__options {
  padding-inline-start: 0px;
}

/* .select-search-box:after {
  font-family: "Font Awesome 5 Free" !important;
  content: "\f078" !important;
}

.select-search-box--focus:after {
  content: "\f00e" !important;
} */
/* Password Policy on signup page */
.password-policy .pp-action {
  cursor: pointer;
  color: #00ccd8;
  text-decoration: underline;
  width: 70%;
  margin: 0 auto;
  text-align: left;
  display: block;
}
.pet-info .pet-icon > div {
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  width: 80%;
  min-height: 70px;
  border-radius: 50%;
  margin: 10px auto;
}
.pet-info .pet-icon i.edit-pet {
  color: $themeColor2;
  cursor: pointer;
}

.pet-info .pet-icon i.edit-pet:hover {
  color: $themeColor2Hover;
}
.pet-info .pet-detail {
  padding-bottom: 20px;
}

#pet-image-preview {
  background-color: #ccc;
  background-image: none;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid #ccc;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-top: 20px;
}

.myaccount-header {
  background-color: #00c7d6;
  color: #fff;
  margin-bottom: 20px;
}

.myaccount-header h4 {
  padding: 20px 15px 20px 0;
  font-size: 24px;
  font-weight: 600;
}

/* priicng dialog border */
div.subscription-dialog .consult-type:before {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 25px 25px 25px 0;
  border-color: transparent #eb425a transparent transparent;
  position: absolute;
  left: -9px;
  top: 46px;
  content: ' ';
}

.form-stripe .stripe-element div.StripeElement {
  margin-top: 15px;
  padding-left: 0;
  padding-right: 0;
}

.vetchat-purchase .proceed-text {
  padding-left: 0;
  padding-right: 0;
}

.petsure-offer .hero-content {
  background-color: transparent;
  padding: 0;
  margin: 80px auto 80px;
}

.petsure-offer .consult-price span.amount {
  font-weight: 700;
}

.petsure-offer .consult-price span.offer {
  opacity: 1;
}
.petsure-offer .consult-message,
.petsure-offer .consult-purchase {
  margin: 0px !important;
  background-color: #fff;
}

.petsure-offer .consult-message p {
  text-align: left;
  padding: 10px 32px;
  position: relative;
}
.petsure-offer .consult-message p:before {
  content: '\e013';
  font-family: 'Glyphicons Halflings';
  position: absolute;
  left: 6px;
}

div.vetchat-purchase.purchase-container form,
div.offer.form-view form {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
}

div.vetchat-purchase.purchase-container form h3,
div.offer.form-view form h3 {
  font-weight: 700;
}

div.form-view h3.block-title {
  color: #54c4c6;
}

div.vetchat-purchase.purchase-container form .col,
div.offer.form-view .col {
  flex-grow: 1;
  flex: 1;
  background-color: #f8f9fb;
  align-self: stretch;
  padding: 80px 16px;
}

div.vetchat-purchase.purchase-container form .col:nth-child(2),
div.offer.form-view .col:nth-child(2) {
  background-color: #fff;
}

div.vetchat-purchase.purchase-container form .col:nth-child(2) input,
div.vetchat-purchase.purchase-container form .col:nth-child(2) select,
div.offer.form-view .col:nth-child(2) input,
div.offer.form-view .col:nth-child(2) select {
  background-color: #f8f9fb;
}

div.vetchat-purchase .col .coupon,
div.offer.form-view .col .coupon {
  margin-bottom: 20px;
}

div.vetchat-purchase .col .coupon .coupon-input,
div.form-view .coupon .coupon-input {
  position: relative;
}

div.vetchat-purchase input,
div.form-view input {
  border: none;
  cursor: pointer;
}

div.vetchat-purchase .coupon input,
div.form-view .coupon input {
  max-width: 80%;
  display: inline-block;
  border-right: none;
}

div.vetchat-purchase select,
div.form-view select {
  width: 100%;
  margin: 10px 0 20px 0;
  padding: 10px 14px;
  border: none;
  cursor: pointer;
}

div.vetchat-purchase select:disabled,
div.form-view select:disabled {
  color: #ccc;
}

div.vetchat-purchase .coupon button.btn-coupon-check,
div.form-view .coupon button.btn-coupon-check {
  border-radius: 50%;
  background-color: #e6e6e6;
  border: 1px solid #e6e6e6;
  position: absolute;
  top: 10px;
  right: 14%;
  width: 44px;
  height: 44px;
}

div.vetchat-purchase .coupon button.btn-coupon-check:focus,
div.form-view .coupon button.btn-coupon-check:focus {
  outline: none;
}

div.vetchat-purchase .coupon button.btn-coupon-check:hover,
div.form-view .coupon button.btn-coupon-check:hover {
  background-color: #e6e6e6aa;
}

div.form-view .btn.btn-confirm {
  background-color: #54c4c6;
  color: #fff;
}

div.vetchat-purchase p.tc-agree input[type='checkbox'],
div.form-view p.tc-agree input[type='checkbox'] {
  display: inline-block;
  width: 18px;
  vertical-align: middle;
  margin-top: 0px;
}

div.vetchat-purchase p.tc-agree span,
div.form-view p.tc-agree span {
  display: inline-block;
  width: calc(100% - 18px);
  font-weight: 400;
  font-size: 12px;
}

div.vetchat-purchase .btn.btn-pay:hover,
div.form-view .btn.btn-confirm:hover {
  opacity: 0.8;
}

span.submitting > div {
  width: 20px;
  margin: 8px auto 0;
}

div.offer-tc {
  background-color: #333;
  color: #fff;
}

div.offer-tc div.container {
  padding-top: 40px;
}

div.offer-tc p {
  font-size: 12px;
}

div.alert-offer.alert {
  margin-bottom: 0;
}

div.vetchat-purchase div.consult-price span.price,
div.vetchat-purchase div.consult-price span.final.discounted {
  font-weight: 600;
}

div.vetchat-purchase div.consult-price span.price.discounted {
  text-decoration: line-through;
  margin-right: 8px;
  font-weight: 400;
}

div.vetchat-purchase textarea {
  width: 100%;
  min-height: 140px;
  background-color: #f8f9fb;
  resize: none;
  outline: none;
  padding: 10px 14px;
}

header.petstock-header {
  width: 100%;
  nav.navbar-default {
    background-color: $themeColor;
    margin: 0;
    border: none;
    border-radius: 0;

    .navbar-header {
      float: none;
      margin: 10px;

      a.home-link {
        max-width: 190px;
        margin: 0 auto;

        img.header-logo {
          max-height: 50px;
        }
      }
    }
  }
  nav.navbar.navbar-default.header-second {
    background-color: $themeColor2;
    color: #fff;

    ul.nav.navbar-nav {
      display: block;

      li {
        display: inline-block;
      }

      @media screen and (min-width: 768px) {
        float: none;

        & > li {
          float: none;
        }
      }
    }

    a {
      color: #fff;
      text-transform: uppercase;
    }

    li:hover {
      background-color: #fff;

      a {
        color: $themeColor;
      }
    }
  }

  @media screen and (max-width: 768px) {
    nav.navbar.navbar-default {
      min-height: 0px;
    }
    .navbar-collapse.in .nav.navbar-nav {
      top: 0;
    }
  }
}

.home-banner {
  background-image: url('/images/PETstock_vet-header.jpg');
  background-size: cover;
  background-position: center center;

  div.banner-logo {
    min-height: 450px;
    position: relative;
    overflow: hidden;

    img {
      position: absolute;
      top: 10%;
      left: 2%;
      // transform: translateY(-50%);
      height: 140px;
      max-width: 100%;

      @media screen and (max-width: 768px) {
        top: 0;
        left: 0;
      }

      @media screen and (max-width: 420px) {
        top: 20px;
        left: 50%;
        transform: translateX(-50%);
        width: 85%;
        height: auto;
      }
    }
  }

  /* @media screen and (min-width: 1500px) {
    background-position: center top;

    div.banner-logo {
      min-height: 650px;
    }
  } */
}

.homePage {
  .top-content {
    h2.section-title {
      font-weight: 700;
      padding-top: 40px;
      margin-top: 0;
      padding-bottom: 0;
    }
    p.desc {
      text-align: center;

      @media screen and (max-width: 768px) {
        margin: 0;
      }
    }
  }

  div.purchase-table {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    color: #fff;

    .purchase-header {
      width: 100%;
      background-color: $themeColor;
      border-radius: 1.2rem 1.2rem 0 0;

      div.purchase-title {
        background-color: $themeColor3;
        border-radius: 1.2rem 1.2rem 0 0;

        span.heading {
          display: block;
          padding-top: 8px;
          padding-bottom: 8px;
          font-weight: 700;
          font-size: 30px;
          font-family: 'DINCondensedWebNormal', 'Helvetica Neue', sans-serif;
        }
      }

      h3.price {
        margin-bottom: 0px;
        padding-bottom: 20px;
        font-weight: 700;
        span {
          display: block;
          font-size: 30px;
          color: #fff;
        }
        small {
          display: block;
          color: #fff;
        }
      }
    }
    .purchase-type {
      display: flex;
      width: 100%;
      border-radius: 0 0 1.2rem 1.2rem;

      @media screen and (max-width: '768px') {
        display: block;
      }

      .type {
        width: 50%;
        flex-grow: 1;
        border: 1px solid #333;
        border-top: none;

        &.type-chat {
          border-radius: 0 0 0 1.2rem;
          .details {
            border-radius: 0 0 0 1.2rem;
          }

          @media screen and (max-width: '768px') {
            border-radius: 0 0 0 0;
            details {
              border-radius: 0 0 0 0;
            }
          }
        }

        &.type-video {
          border-radius: 0 0 1.2rem 0;

          .details {
            border-radius: 0 0 1.2rem 0;
          }

          @media screen and (max-width: '768px') {
            border-radius: 0 0 1.2rem 1.2rem;
            .details {
              border-radius: 0 0 1.2rem 1.2rem;
            }
          }
        }

        @media screen and (max-width: '768px') {
          width: 100%;
        }
      }

      div.title span {
        display: block;
        padding-top: 8px;
        padding-bottom: 8px;
        text-transform: uppercase;
      }

      .details {
        background-color: #fff;
        color: #333;

        ul {
          padding-top: 20px;
          padding-bottom: 20px;
          padding-inline-start: 35%;

          li {
            list-style: none;
            text-align: left;
            position: relative;

            i.fa {
              color: $themeColor3;
              margin-right: 4px;
            }
          }
        }

        span {
          text-align: center;
          display: block;
          padding-left: 20px;
        }

        .btn {
          text-transform: capitalize;
          color: #fff;
          margin-bottom: 20px;
        }
      }

      .type-chat {
        border-right: 0.5px solid #333;
      }
      .type-voideo {
        border-left: 0.5px solid #333;
      }
    }
  }
}

.how-vetchat-works {
  text-align: center;

  h2.hiw-title {
    padding-top: 40px;
    font-weight: 700;
  }

  .row-eq-height {
    display: flex;
    /* flex-direction: column; */
    flex-wrap: wrap;
    border-radius: 1.2rem;
    .how-steps {
      color: #fff;
      display: flex;
      flex-direction: column;
      padding-left: 0px;
      padding-right: 0px;
      border: 1px solid #333;
      border-bottom: none;

      .steps-header {
        font-weight: 700;
        font-size: 45px;
        line-height: 1.1;
        margin-bottom: 0;
        margin-top: 0;
      }

      .steps-content {
        margin: 0 15%;
        padding: 10px;
        min-height: 350px;

        @media screen and (max-width: 768px) {
          min-height: 320px;
        }
      }

      &:nth-child(1) {
        background-color: $themeColor;
        border-right-width: 0.5px;
        border-radius: 1.2rem 0 0 1.2rem;

        h3 {
          color: #fff;
        }

        .steps-image {
          border-radius: 1.2rem 0 0 0;
        }

        @media screen and (max-width: 768px) {
          border-radius: 1.2rem 1.2rem 0 0;

          .steps-image {
            border-radius: 1.2rem 1.2rem 0 0;
          }
        }

        .steps-header {
          color: $themeColor3;
        }
      }

      &:nth-child(2) {
        background-color: $themeColor3;
        border-left-width: 0.5px;
        border-right-width: 0.5px;

        .steps-header {
          color: $themeColor;
        }
      }

      &:nth-child(3) {
        background-color: $themeColor;
        border-left-width: 0.5px;
        border-radius: 0 1.2rem 1.2rem 0;

        .steps-header {
          color: $themeColor3;
        }

        .steps-image {
          border-radius: 0 1.2rem 0 0;
        }

        h3 {
          color: #fff;
        }

        @media screen and (max-width: 768px) {
          border-radius: 0 0 1.2rem 1.2rem;

          .steps-image {
            border-radius: 0 0 0 0;
          }
        }
      }

      .steps-image {
        // background-color: #fff;
        width: 100%;
        // margin: 0 -15px;
        img {
          width: 75%;
          max-width: 150px;
          margin: 20px auto 0;
        }
      }
    }

    p.section-subtitle {
      margin: -32px 0 20px;
    }
  }
}

.ourVets {
  padding-bottom: 80px;

  h2.section-title {
    text-align: center;
    font-weight: 700;
    padding-top: 40px;
    padding-bottom: 0px;
  }

  div.carousel-vets {
    color: #fff;

    div.vet-detail {
      min-height: 270px;

      &:nth-child(1) {
        border-radius: 1.2rem 0 0 1.2rem;
      }

      &:nth-child(3) {
        border-radius: 0 1.2rem 1.2rem 0;
      }

      @media screen and (max-width: 990px) {
        min-height: 312px;
      }
      /* @media screen and (min-width: 990px) {
        min-height: 270px;
      } */

      @media screen and (max-width: 768px) {
        &:nth-child(1) {
          border-radius: 1.2rem 1.2rem 0 0;
        }

        &:nth-child(3) {
          border-radius: 0 0 1.2rem 1.2rem;
        }
      }

      &:nth-child(even) {
        background-color: $themeColor;
      }

      &:nth-child(odd) {
        background-color: $themeColor3;
      }
      div.title {
        padding-top: 12px;

        div.image {
          text-align: right;

          img {
            width: 80%;
          }
        }

        h4.vet-name {
          font-weight: 700;
          color: #fff;
        }

        div.separator {
          display: block;
          hr {
            width: 30%;
            margin: 0 auto;
          }
        }
      }
    }

    div.info {
      ul {
        padding-top: 20px;
        padding-bottom: 20px;

        li {
          list-style: none;
          text-align: left;
          position: relative;

          &:before {
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            content: '\f00c';
            position: absolute;
            left: -24px;
            top: 0px;
          }
        }
      }
    }
  }
}

.vetchat-purchase {
  h3.block-title {
    margin-bottom: 26px;
    text-transform: capitalize;
  }

  input,
  textarea,
  select {
    border-radius: 8px;
  }

  .petstock-clinic label {
    input[type='checkbox'] {
      display: inline-block;
      width: auto;
      vertical-align: top;
      margin-top: 6px;
      margin-right: 8px;
      cursor: pointer;
    }

    span {
      display: inline-block;
      cursor: pointer;
    }
  }

  .btn-pay.btn-confirm {
    text-transform: uppercase;
  }
}

div.purchase-form-stripe {
  .stripe-element .StripeElement {
    border-radius: 8px;
  }
}

div.testimonials {
  h2.section-title {
    padding-top: 40px;
    padding-bottom: 0px;
    font-weight: 700;
  }
}
